// extracted by mini-css-extract-plugin
export var root = "main_root__QPT7o";
export var xtContent = "main_xt-content__zsChH";
export var xtContentWithRemarks = "main_xt-content-with-remarks__U-2QE";
export var muiFormHelperTextRoot = "main_MuiFormHelperText-root__h+6Px";
export var xtListPageContainer = "main_xt-list-page-container__xZZvM";
export var muiInputBaseRoot = "main_MuiInputBase-root__pS2ai";
export var muiTextFieldRoot = "main_MuiTextField-root__VRMP0";
export var muiTablePaginationRoot = "main_MuiTablePagination-root__LGfAO";
export var muiTablePaginationSelectRoot = "main_MuiTablePagination-selectRoot__o2+T3";
export var muiTablePaginationSelect = "main_MuiTablePagination-select__ZkJHX";
export var muiStepperRoot = "main_MuiStepper-root__7bNwB";
export var muiStepperHorizontal = "main_MuiStepper-horizontal__HhBZC";
export var muiStepIconRoot = "main_MuiStepIcon-root__iWsQ0";
export var muiStepIconActive = "main_MuiStepIcon-active__yDQ8M";
export var muiStepIconCompleted = "main_MuiStepIcon-completed__r1ZkW";
export var muiListItemRoot = "main_MuiListItem-root__Nv7ba";
export var muiSelected = "main_Mui-selected__o2gPe";
export var xtPageTitle = "main_xt-page-title__QUxBf";
export var xtSectionBorder = "main_xt-section-border__A+Efm";
export var xtPageHeader = "main_xt-page-header__4t2Aq";
export var xtDialogScrollable = "main_xt-dialog-scrollable__FgHHL";
export var reactToastNotificationsContainer = "main_react-toast-notifications__container__S5ufH";
export var xtScrollbarStyle = "main_xt-scrollbar-style__m8YuN";
export var xtModalDetailsContent = "main_xt-modal-details-content__u9Dc5";
export var mainContainer = "main_mainContainer__ymsDK";
export var xtDialogDetailsContent = "main_xt-dialog-details-content__-T1EQ";
export var xtLazyModuleLoaderTabContainer = "main_xt-lazy-module-loader-tab-container__ti-JX";
export var xtTextTruncated = "main_xt-text-truncated__+qnja";
export var xtSummaryContent = "main_xt-summary-content__xAegU";
export var xtSummaryLabel = "main_xt-summary-label__37qgj";
export var visibleHidden = "main_visible-hidden__z00Iu";
export var mainPanel = "main_mainPanel__2XOp7";