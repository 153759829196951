// extracted by mini-css-extract-plugin
export var root = "xt-text-area_root__ucSd1";
export var xtContent = "xt-text-area_xt-content__m5+XY";
export var xtContentWithRemarks = "xt-text-area_xt-content-with-remarks__zYHFW";
export var muiFormHelperTextRoot = "xt-text-area_MuiFormHelperText-root__ADlut";
export var xtListPageContainer = "xt-text-area_xt-list-page-container__dAdDp";
export var muiInputBaseRoot = "xt-text-area_MuiInputBase-root__0eTmB";
export var muiTextFieldRoot = "xt-text-area_MuiTextField-root__dR9+9";
export var muiTablePaginationRoot = "xt-text-area_MuiTablePagination-root__QoGvP";
export var muiTablePaginationSelectRoot = "xt-text-area_MuiTablePagination-selectRoot__kp4d0";
export var muiTablePaginationSelect = "xt-text-area_MuiTablePagination-select__7Tv3v";
export var muiStepperRoot = "xt-text-area_MuiStepper-root__T7xnQ";
export var muiStepperHorizontal = "xt-text-area_MuiStepper-horizontal__EEijA";
export var muiStepIconRoot = "xt-text-area_MuiStepIcon-root__rsxIA";
export var muiStepIconActive = "xt-text-area_MuiStepIcon-active__iNpYp";
export var muiStepIconCompleted = "xt-text-area_MuiStepIcon-completed__Jc7rn";
export var muiListItemRoot = "xt-text-area_MuiListItem-root__P63Nf";
export var muiSelected = "xt-text-area_Mui-selected__M5bV5";
export var xtPageTitle = "xt-text-area_xt-page-title__-IjSw";
export var xtSectionBorder = "xt-text-area_xt-section-border__ThN9l";
export var xtPageHeader = "xt-text-area_xt-page-header__02TXS";
export var xtDialogScrollable = "xt-text-area_xt-dialog-scrollable__vTr8U";
export var reactToastNotificationsContainer = "xt-text-area_react-toast-notifications__container__-YV7C";
export var xtScrollbarStyle = "xt-text-area_xt-scrollbar-style__bdCQN";
export var xtModalDetailsContent = "xt-text-area_xt-modal-details-content__PFRpO";
export var xtDialogDetailsContent = "xt-text-area_xt-dialog-details-content__6-nPH";
export var xtLazyModuleLoaderTabContainer = "xt-text-area_xt-lazy-module-loader-tab-container__Ow-yA";
export var xtTextTruncated = "xt-text-area_xt-text-truncated__wYvjn";
export var xtSummaryContent = "xt-text-area_xt-summary-content__fRbkc";
export var xtSummaryLabel = "xt-text-area_xt-summary-label__fcBqF";
export var visibleHidden = "xt-text-area_visible-hidden__JCd5D";
export var textArea = "xt-text-area_textArea__i-4vg";