import { ISetupModule } from './setup-wizard.types'
import { CreateItems } from './basic-configuration-steps/create-items/create-items'
import { CreateAccounts } from './basic-configuration-steps/create-accounts/create-accounts'
import { WelcomeMsg } from './basic-configuration-steps/welcome-msg/welcome-msg'

export { SetupWizard } from './setup-wizard'
export { SetupWizardService } from './setup-wizard.service'

export enum SetupModules {
  BasicConfiguration = 'Basic Configuration',
  Sales = 'Sales',
}

export enum SetupModulesParams {
  BasicConfiguration = 'basic',
  Sales = 'sales',
}

export enum BasicConfigSteps {
  Welcome = 'welcome',
  Items = 'items',
  Accounts = 'accounts',
}

export enum BasicConfigStepParams {
  Welcome = 'welcomeMsg',
  Items = 'createItems',
  Accounts = 'createAccounts',
}

export const setupModuleList: ISetupModule[] = [
  {
    id: 1,
    name: SetupModules.BasicConfiguration,
    param: SetupModulesParams.BasicConfiguration,
    steps: [
      {
        id: 1,
        name: BasicConfigSteps.Welcome,
        label: 'Welcome',
        component: WelcomeMsg,
        param: BasicConfigStepParams.Welcome,
      },
      {
        id: 2,
        name: BasicConfigSteps.Items,
        label: 'Create items',
        component: CreateItems,
        param: BasicConfigStepParams.Items,
      },
      {
        id: 3,
        name: BasicConfigSteps.Accounts,
        label: 'Create accounts',
        component: CreateAccounts,
        param: BasicConfigStepParams.Accounts,
      },
    ],
  },
]

export enum SetupWizardAction {
  Back = 'Go Back',
  Skip = 'Skip',
  Cancel = 'Cancel',
  Next = 'Next',
  Save = 'Save',
}
