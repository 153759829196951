import { IHttpClient } from 'core/services/http-client'
import { SettingType } from 'setup/settings/settings.types'
import { ICreateItemsTableItem } from './basic-configuration-steps/create-items/create-items.types'
import { ICreateAccountsTableItem } from './basic-configuration-steps/create-accounts/create-accounts.types'
import { ItemCreationError, AccountCreationError } from './setup-wizard.types'
import { normalizeCustomerPayload, normalizeProspectPayload } from './basic-configuration-steps/create-accounts/create-accounts.utils'

export interface ISetupWizardService {
  createItems(items: ICreateItemsTableItem[]): Promise<void>
  createAccounts(customers: ICreateAccountsTableItem[]): Promise<void>
  updateSetting(setting: boolean): Promise<void>
}

export class SetupWizardService implements ISetupWizardService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async createItems(items: ICreateItemsTableItem[]): Promise<void> {
    if (!items.length) {
      return
    }
    try {
      items.map(async (item, index) => {
        const data = { ...item, item_number: item.item_number.toUpperCase(), id: index, inventory_uom_name: 'EA' }
        await this.apiClient.post('/item/create', { data })
      })
    } catch (e) {
      throw new ItemCreationError()
    }
  }

  public async createAccounts(accounts: ICreateAccountsTableItem[]): Promise<void> {
    if (!accounts.length) {
      return
    }
    try {
      accounts.map(async (account) => {
        if (account.account_type == 'Customer') {
          await this.apiClient.post('/customer/create', { data: normalizeCustomerPayload(account) })
          return
        }
        await this.apiClient.post('/prospect/create', { data: normalizeProspectPayload(account) })
      })
    } catch (e) {
      throw new AccountCreationError()
    }
  }

  public async updateSetting(setting: boolean): Promise<void> {
    try {
      const data = {
        setting: SettingType.XTSetupWizardRun,
        setting_value: setting == true ? 't' : 'f',
      }
      await this.apiClient.post('/settings/set', { data })
    } catch (error) {
      console.error(error)
    }
  }
}
