function defineMinValidatorMessage(minValue: number | string, scale: number = 0): string {
  const minValueString = typeof minValue === 'number' ? minValue.toFixed(scale) : minValue

  return `Should be more than ${minValueString}.`
}

function defineMaxValidatorMessage(maxValue: number | string, scale: number = 0): string {
  const maxValueString = typeof maxValue === 'number' ? maxValue.toFixed(scale) : maxValue

  return `Should be less than ${maxValueString}.`
}

export const validationMessage = {
  isRequired: 'Required.',
  alreadyUsed: 'Already in use.',
  invalidNumber: 'Invalid number.',
  invalidDate: 'Invalid date format.',
  invalidEmail: 'Please enter a valid email address.',
  invalidPhone: 'Please enter a valid phone number.',
  invalidFormat: 'Invalid format.',
  invalidPatternValidator: 'Validator should have valid Regex syntax.',
  invalidInputMask: 'Mask is invalid. Please check the mask definitions.',
  inputMaskHasConflictWithValidator: "Mask shouldn't conflict with the validator.",
  minimumDate: '01/01/1900',
  passwordMatch: 'Passwords do not match.',
  min: defineMinValidatorMessage,
  max: defineMaxValidatorMessage,
  cannotBeGreaterThan: (value: string) => `Cannot be greater than ${value}.`,
  cannotBeLessThan: (value: string) => `Cannot be less than ${value}.`,
  minDate: (min: string) => `Should be later than ${min}.`,
  maxDate: (max: string) => `Should be earlier than ${max}.`,
  notEqual: (value: string) => `Should differ from ${value}`,
  equals: (value: string) => `Should be equal to ${value}.`,
  diapason: (startValue: string | number, endValue: string | number) => `Should match diapason from ${startValue} to ${endValue}.`,
}

export const validationRegExp: {
  phone: RegExp
  email: RegExp
  fileType: RegExp
} = {
  phone: /^\+?[1-9]\d{3,14}$/,
  email: /(?!.*\.\..*)(?!.*--.*)(?!.* .*)(?!.*@@.*)(?!.*(\.|@|^)-.*)(?!.*\.(\.|@|$).*)(?!.*(\.|@)[A-Za-z0-9-]{64,}(\.|$).*)^[A-Za-z0-9][A-Za-z0-9!#$%&'*+\/=?^_`{|}~.-]{0,62}[^.]?@[A-Za-z0-9.:\-\[\]]{0,253}[A-Za-z0-9\[\]][.][A-Za-z.]{0,62}?$/,
  fileType: /(jpg|jpeg|png|gif|bmp|tiff)$/i,
}

export function validatePhone(phone?: string): boolean {
  if (!phone) {
    return false
  }
  return validationRegExp.phone.test(phone)
}

export function validateEmail(email?: string): boolean {
  if (!email) {
    return false
  }
  return validationRegExp.email.test(email)
}

export function validateFileType(type?: string): boolean {
  if (!type) {
    return false
  }
  return validationRegExp.fileType.test(type)
}

export const maxNumber = 10 ** 10
export const minDate = '1900-01-01'
