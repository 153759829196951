import { Overwrite } from 'utility-types'
import { Injector } from 'core/injector/injector.service'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { IProductsModuleServices, ProductsModule, ProductsModuleDefinition } from './products.module'
import {
  bomItemsServiceToken,
  bomServiceToken,
  bomUtilsServiceToken,
  itemSitesServiceToken,
  itemSitesUtilsServiceToken,
  itemsServiceToken,
  itemsUtilsServiceToken,
  routingServiceToken,
  routingUtilsServiceToken,
} from './products.constants'
import { IRoutingService } from './routing/routing.service'
import { IBomService } from './bom/bom.service'
import { IItemsService } from './items/items.service'
import { IBomItemsService } from './bom/bom-item/bom-item.service'
import { IBomUtilsService } from './bom/bom-utils.service'
import { IItemsUtilsService } from './items/items-utils.service'
import { IRoutingUtilsService } from './routing/routing-utils.service'
import { IItemSitesService } from './items/item-sites.service'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'
import { IItemSitesUtilsService } from './items/item-sites-utils.service'

function resolveProductsModule(): Overwrite<ProductsModuleDefinition, IProductsModuleServices> {
  const RoutingService = Injector.resolve<IRoutingService>(routingServiceToken)
  const BomService = Injector.resolve<IBomService>(bomServiceToken)
  const ItemsService = Injector.resolve<IItemsService>(itemsServiceToken)
  const ItemSitesService = Injector.resolve<IItemSitesService>(itemSitesServiceToken)
  const ItemSitesUtilsService = Injector.resolve<IItemSitesUtilsService>(itemSitesUtilsServiceToken)
  const BomItemsService = Injector.resolve<IBomItemsService>(bomItemsServiceToken)
  const BomUtilsService = Injector.resolve<IBomUtilsService>(bomUtilsServiceToken)
  const ItemsUtilsService = Injector.resolve<IItemsUtilsService>(itemsUtilsServiceToken)
  const RoutingUtilsService = Injector.resolve<IRoutingUtilsService>(routingUtilsServiceToken)

  const module = LazyModulesService.resolveModule<ProductsModuleDefinition>(ProductsModule)

  return {
    ...module,
    BomService,
    RoutingService,
    ItemsService,
    BomItemsService,
    BomUtilsService,
    ItemsUtilsService,
    RoutingUtilsService,
    ItemSitesService,
    ItemSitesUtilsService,
  }
}

export const useProductsModule = moduleHookResolver(resolveProductsModule)
