import { ITableRow } from 'components/table/table.types'
import { ISetupWizardModuleState } from '../../setup-wizard.types'

export interface ICreateAccounts {
  onChange(formData: ICreateAccountsState, isDirty: boolean): void
  onSubmit(): void
  state: ISetupWizardModuleState
}

export interface IAccountPhone {
  role: string
  number: string
  allowsms: boolean
}

export interface IAccountAddress {
  address1: string
  address2: string
  city: string
  state: string
  country: string
  postalcode: string | number
}

export interface IAccountContact {
  first_name: string
  middle_name: string
  last_name: string
}

export interface IAccountCustomerContact extends IAccountContact {
  phones: IAccountPhone[]
  contact_address: IAccountAddress
}

export interface IAccountProspectContact extends IAccountContact {
  phones: IAccountPhone[]
  contact_address: IAccountAddress
}

export interface IAccount extends IAccountAddress {
  id: string
  account_number: string
  account_type: string
  account_name: string
  first_name: string
  middle_name: string
  last_name: string
  active: boolean
  phone: string
  allowsms: boolean
  email: string
}

export interface IAccountCustomerPayload {
  customer_number: string
  customer_name: string
  active: boolean
  billing_contact: IAccountCustomerContact
}

export interface IAccountProspectPayload {
  number: string
  name: string
  active: boolean
  contacts: IAccountContactObject[]
}

export interface IAccountContactObject {
  contact: IAccountProspectContact
}

export interface ICreateAccountsTableItem extends ITableRow, IAccount {
  id: string
}

export enum CreateAccountFormAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  New = 'New',
}

export interface ICreateAccountsState {
  accounts: ICreateAccountsTableItem[]
}
