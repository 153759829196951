import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { activitiesRoute } from 'activities/activities.routes'
import { processRoutes } from 'common/routing/routing.utils'
import { companiesRoute } from 'companies/companies.routes'
import { XtVersion } from 'components/version/version'
import { costingRoute } from 'costing/costing.routes'
import { homeRoute } from 'home/home.routes'
import { setupWizardRoute } from 'setup-wizard/setup-wizard.routes'
import { lotSerialRoute } from 'inventory-adjustments/inventory-adjustments.routes'
import { maintainShippingRoute } from 'maintain-shipping/maintain-shipping.routes'
import { productRoute } from 'products/products.routes'
import { runningAvailabilityRoute } from 'running-availability/running-availability.routes'
import { setupRoute } from 'setup/setup.routes'
import { shippingRoute } from 'shipping/shipping.routes'
import { ordersRoute } from 'orders/orders.routes'

const versionRoute: IRoute = {
  path: '/version',
  component: XtVersion,
}

const routes: Array<IRoute | IAsyncRoute<never>> = [
  homeRoute,
  companiesRoute,
  activitiesRoute,
  productRoute,
  ordersRoute,
  setupRoute,
  shippingRoute,
  versionRoute,
  runningAvailabilityRoute,
  costingRoute,
  lotSerialRoute,
  maintainShippingRoute,
  setupWizardRoute,
]

export const appRoutes = processRoutes(routes)
