import { ICreateAccountsTableItem, IAccountCustomerPayload, IAccountProspectPayload } from './create-accounts.types'

export function normalizeCustomerPayload(data: ICreateAccountsTableItem): IAccountCustomerPayload {
  const newData = {
    customer_number: data.account_number.toUpperCase(),
    customer_name: data.account_name,
    active: true,
    billing_contact: {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      phones: [
        {
          role: 'Primary',
          number: data.phone,
          allowsms: data.allowsms,
        },
      ],
      contact_address: {
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        country: data.country,
        postalcode: data.postalcode,
      },
    },
  }
  return newData
}
export function normalizeProspectPayload(data: ICreateAccountsTableItem): IAccountProspectPayload {
  const newData = {
    number: data.account_number.toUpperCase(),
    name: data.account_name,
    active: true,
    contacts: [
      {
        contact: {
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          email: data.email,
          phones: [
            {
              role: 'Primary',
              number: data.phone,
              allowsms: data.allowsms,
            },
          ],
          contact_address: {
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            country: data.country,
            postalcode: data.postalcode,
          },
        },
      },
    ],
  }
  return newData
}
