import * as Yup from 'yup'
import { validationMessage, validationRegExp } from 'common/validation/validation'
import { CreateAccountFormField } from './create-account-dialog.constants'

export const CreateAccountFormSchema = Yup.object().shape({
  [CreateAccountFormField.AccountNumber]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired)
    .trim(),
  [CreateAccountFormField.AccountName]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [CreateAccountFormField.Phone]: Yup.string().matches(validationRegExp.phone, {
    message: validationMessage.invalidPhone,
    excludeEmptyString: true,
  }),
  [CreateAccountFormField.Email]: Yup.string().matches(validationRegExp.email, {
    message: validationMessage.invalidEmail,
    excludeEmptyString: true,
  }),
})
