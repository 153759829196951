import * as Yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { CreateItemFormField } from './create-item-dialog.constants'

export const CreateItemFormSchema = Yup.object().shape({
  [CreateItemFormField.Number]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired).trim(),
  [CreateItemFormField.Description]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [CreateItemFormField.ItemType]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [CreateItemFormField.Price]: Yup.number().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
})
